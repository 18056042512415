import React from "react"
import { useTranslation } from "react-i18next"
import "./../common.css"
import "./apartment-services.css"


const ApartmentServices = () => {
    const { t } = useTranslation();
    let iconColor = "var(--icon-comp-color)";
    return (
        // TODO: instead of inline icons the embedding using styles coud be used
        // http://google.github.io/material-design-icons/
        <div id="apartment-services" className="elements-container">
            <div style={{ maxWidth:1080, margin:"auto"}}>
                <h1 className="service-text services-title">{t('services.title')}</h1>
                {/* <p>{t("services.text")}</p> */}
                <div className="service-item">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={iconColor} width="80px" height="80px"><path d="M0 0h24v24H0z" fill="none" /><path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" /></svg>
                    <span className="service-text">{t('services.card')}</span>
                </div>
                <div className="service-item">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={iconColor} width="80px" height="80px"><path d="M0 0h24v24H0z" fill="none" /><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z" /></svg>
                    <span className="service-text">{t('services.languages')}</span>
                </div>
                <div className="service-item">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={iconColor} width="80px" height="80px"><path d="M0 0h24v24H0z" fill="none" /><path d="M1 9l2 2c4.97-4.97 13.03-4.97 18 0l2-2C16.93 2.93 7.08 2.93 1 9zm8 8l3 3 3-3c-1.65-1.66-4.34-1.66-6 0zm-4-4l2 2c2.76-2.76 7.24-2.76 10 0l2-2C15.14 9.14 8.87 9.14 5 13z" /></svg>
                    <span className="service-text">{t('services.wifi')}</span>
                </div>
                <div className="service-item">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={iconColor} width="80px" height="80px"><path d="M0 0h24v24H0z" fill="none" /><path d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.5-4.5h11L19 11H5z" /></svg>
                    <span className="service-text">{t('services.parking')}</span>
                </div>
                <div className="service-item">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={iconColor} width="80px" height="80px"><path d="M0 0h24v24H0z" fill="none" /><path d="M22 11h-4.17l3.24-3.24-1.41-1.42L15 11h-2V9l4.66-4.66-1.42-1.41L13 6.17V2h-2v4.17L7.76 2.93 6.34 4.34 11 9v2H9L4.34 6.34 2.93 7.76 6.17 11H2v2h4.17l-3.24 3.24 1.41 1.42L9 13h2v2l-4.66 4.66 1.42 1.41L11 17.83V22h2v-4.17l3.24 3.24 1.42-1.41L13 15v-2h2l4.66 4.66 1.41-1.42L17.83 13H22z" /></svg>
                    <span className="service-text">{t('services.ac')}</span>
                </div>
                <div className="service-item">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={iconColor} width="80px" height="80px"><path d="M0 0h24v24H0z" fill="none" /><path d="M13 2v8h8c0-4.42-3.58-8-8-8zm6.32 13.89C20.37 14.54 21 12.84 21 11H6.44l-.95-2H2v2h2.22s1.89 4.07 2.12 4.42c-1.1.59-1.84 1.75-1.84 3.08C4.5 20.43 6.07 22 8 22c1.76 0 3.22-1.3 3.46-3h2.08c.24 1.7 1.7 3 3.46 3 1.93 0 3.5-1.57 3.5-3.5 0-1.04-.46-1.97-1.18-2.61zM8 20c-.83 0-1.5-.67-1.5-1.5S7.17 17 8 17s1.5.67 1.5 1.5S8.83 20 8 20zm9 0c-.83 0-1.5-.67-1.5-1.5S16.17 17 17 17s1.5.67 1.5 1.5S17.83 20 17 20z" /></svg>
                    <span className="service-text">{t('services.child')}</span>
                </div>
                <div className="service-item">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={iconColor} width="80px" height="80px"><path d="M0 0h24v24H0z" fill="none" /><path d="M11 9H9V2H7v7H5V2H3v7c0 2.12 1.66 3.84 3.75 3.97V22h2.5v-9.03C11.34 12.84 13 11.12 13 9V2h-2v7zm5-3v8h2.5v8H21V2c-2.76 0-5 2.24-5 4z" /></svg>
                    <span className="service-text">{t('services.restaurant')}</span>
                </div>
                <div className="service-item">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={iconColor} width="80px" height="80px"><path d="M0 0h24v24H0V0zm13.97 21.49c-.63.23-1.29.4-1.97.51.68-.12 1.33-.29 1.97-.51zM12 22c-.68-.12-1.33-.29-1.97-.51.64.22 1.29.39 1.97.51z" fill="none" /><path d="M8.55 12c-1.07-.71-2.25-1.27-3.53-1.61 1.28.34 2.46.9 3.53 1.61zm10.43-1.61c-1.29.34-2.49.91-3.57 1.64 1.08-.73 2.28-1.3 3.57-1.64z" /><path d="M15.49 9.63c-.18-2.79-1.31-5.51-3.43-7.63-2.14 2.14-3.32 4.86-3.55 7.63 1.28.68 2.46 1.56 3.49 2.63 1.03-1.06 2.21-1.94 3.49-2.63zm-6.5 2.65c-.14-.1-.3-.19-.45-.29.15.11.31.19.45.29zm6.42-.25c-.13.09-.27.16-.4.26.13-.1.27-.17.4-.26zM12 15.45C9.85 12.17 6.18 10 2 10c0 5.32 3.36 9.82 8.03 11.49.63.23 1.29.4 1.97.51.68-.12 1.33-.29 1.97-.51C18.64 19.82 22 15.32 22 10c-4.18 0-7.85 2.17-10 5.45z" /></svg>
                    <span className="service-text">{t('services.spa')}</span>
                </div>
                <div className="service-item">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={iconColor} width="80px" height="80px"><path d="M0 0h24v24H0z" fill="none" /><path d="M6.76 4.84l-1.8-1.79-1.41 1.41 1.79 1.79 1.42-1.41zM4 10.5H1v2h3v-2zm9-9.95h-2V3.5h2V.55zm7.45 3.91l-1.41-1.41-1.79 1.79 1.41 1.41 1.79-1.79zm-3.21 13.7l1.79 1.8 1.41-1.41-1.8-1.79-1.4 1.4zM20 10.5v2h3v-2h-3zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm-1 16.95h2V19.5h-2v2.95zm-7.45-3.91l1.41 1.41 1.79-1.8-1.41-1.41-1.79 1.8z" /></svg>
                    <span className="service-text">{t('services.sunbed')}</span>
                </div>
            </div>
        </div>
    )
}

export default ApartmentServices