import React from "react"
import "./intro.css"
import "../common.css"
import { useTranslation } from "react-i18next"
import OverviewImg from "../overview-img"

const Intro = () => {
    const { t } = useTranslation();
    return (
        <>
            <div id="intro">
                <div style={{ maxWidth:1080, margin:"auto"}}>
                    <div className="img-div">
                        <OverviewImg />
                    </div>
                    <div className="text-center components-padding text">
                        <h1>{t('intro.title')}</h1>
                        <p>{t('intro.intro')}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Intro;