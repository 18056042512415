import React from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const MyMapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBNMtUKXESezBi9JzQpUF3Aspm4jEXSAvg&v=3.exp&libraries=geometry,drawing,places&query_place_id=ChIJzcxw6eAdaUcRJLFuXm61XGo",
    loadingElement: <div style={{ height: `450px` }} />,
    containerElement: <div style={{ height: `450px` }} />,
    mapElement: <div style={{ height: `400px` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
  <GoogleMap
    defaultZoom={14}
    defaultCenter={{ lat: 46.793221, lng: 17.180016 }}
  >
    <Marker position={{ lat: 46.796160, lng: 17.179868 }} onClick={props.onMarkerClick} defaultTitle="Barbara Apartman" />
  </GoogleMap>
)

class ApartmentMap extends React.PureComponent {

  render() {
    return (
      <MyMapComponent
        isMarkerShown={true}
        onMarkerClick={this.handleMarkerClick}
      />
    )
  }
}

export default ApartmentMap