import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./heviz-imgs.css"

const HevizImgs = () => {
  const allImageData = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "heviz/heviz4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 6000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "heviz/heviz1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 6000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div style={{ maxWidth: 1080, margin: "auto" }} className="imgsContainer">
      <div className="container-fluid imgPadding">
        <Img fluid={allImageData.img1.childImageSharp.fluid} />
      </div>
      <div className="container-fluid imgPadding">
        <Img fluid={allImageData.img2.childImageSharp.fluid} />
      </div>
    </div>
  )
}

export default HevizImgs;