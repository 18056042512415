import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useStaticQuery, graphql } from "gatsby"
import "./../common.css"
import "./contacts.css"
import "./../../theming.css"
import ApartmentMap from "../apartment-map"

const Contacts = () => {
    const { site } = useStaticQuery(
        graphql`
          query {
            site {
                siteMetadata {
                    phone
                    email
              }
            }
          }
        `
    )
    function decode(s) {
        var e = {}, i, b = 0, c, x, l = 0, a, r = '', w = String.fromCharCode, L = s.length;
        var A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
        for (i = 0; i < 64; i++) { e[A.charAt(i)] = i; }
        for (x = 0; x < L; x++) {
            c = e[s.charAt(x)]; b = (b << 6) + c; l += 6;
            while (l >= 8) { ((a = (b >>> (l -= 8)) & 0xff) || (x < (L - 2))) && (r += w(a)); }
        }
        return r;
    };

    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    useEffect(() =>  decodeData(),[]);
    function decodeData() {
        setPhone(() => decode(site.siteMetadata.phone),[]);
        setEmail(() => decode(site.siteMetadata.email),[]);
    }

    const { t } = useTranslation();
    let iconColor = "var(--icon-comp-color)";
    let selectableIconColor = "var(--apartment-dark-blue)";
    return (
        <div id="contacts" className="contact-icons">
            <div style={{ maxWidth: 1080, margin: "auto" }}>
                <h1 className="common-text">{t('contacts.contacts')}</h1>
                <div>
                    <p className="contacts-text">{t('contacts.text')}</p>
                    <div className="service-item">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={iconColor} width="80px" height="80px"><path d="M0 0h24v24H0z" fill="none" /><path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z" /></svg>
                        <span className="service-text">{phone}</span>
                    </div>
                    <div className="service-item">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={iconColor} width="80px" height="80px"><path d="M0 0h24v24H0z" fill="none" /><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" /></svg>
                        <span className="service-text">{email}</span>
                    </div>
                </div>
                <div className="service-item">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={selectableIconColor} width="80px" height="80px"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" /></svg>
                    <span className="service-text">{t('contacts.address')}</span>
                </div>
                <div style={{ paddingTop: 20, paddingBottom: 10 }}><a className="contact-ref" href="https://www.google.com/maps/dir//H%C3%A9v%C3%ADz,+Barbara+Apartman,+Budai+Nagy+Antal+u.+24,+8380+Hungary/@46.796221,17.180016,17z">{t('contacts.direction')}</a></div>
                <ApartmentMap />
            </div>
        </div>
    )
}


export default Contacts