/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"

function SEO() {

  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={t('seo.title')}
      titleTemplate={`${t('seo.title')}`}
      meta={[
        {
          name: `description`,
          content: t('seo.description'),
        },
        {
          property: `og:title`,
          content: t('seo.title'),
        },
        {
          property: `og:description`,
          content: t('seo.description'),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: t('seo.author'),
        },
        {
          name: `twitter:title`,
          content: t('seo.title'),
        },
        {
          name: `twitter:description`,
          content: t('seo.description'),
        },
        {
          name: `keywords`,
          content: `barbara, apartman, hévíz, szállás, klimatizált, erkélyes, OTP szép kártya, apartment, accommodation, air conditioned, balcony, Unterkunft, Klimaanlage, Balkon`
        }
      ]}
    />
  )
}

export default SEO
