import React from "react"
import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'
import { useStaticQuery, graphql } from "gatsby"
import './apartment-gallery.css'
import './../common.css'
import { useTranslation } from "react-i18next"

let scrollbarValue = 0;

const ApartmentGallery = () => {
  const { t } = useTranslation();
  const allData = useStaticQuery(graphql`
    query ImagesForGallery {
        images: allFile(
          filter: { relativeDirectory: { eq: "gallery" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              childImageSharp {
                thumb: fluid(maxWidth: 580, maxHeight: 580) {
                  ...GatsbyImageSharpFluid
                }
                full: fluid(maxWidth: 6000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      `)

  setTimeout(function () {
    if (scrollbarValue !== 0) {
      window.scrollTo(0, scrollbarValue);
      scrollbarValue = 0;
    }
  }, 100);

  function handleGalleryContainerClick(event) {
    const imgClick = event.target.nodeName === "IMG" && event.target.className === "";
    const divClick = event.target.nodeName === "DIV" && event.target.className === "sc-AxirZ chABvK";
    if (window.history.state !== "GalleryImage" && (imgClick || divClick)) {
      window.history.pushState("GalleryImage", "GalleryImage", "");
    }
    window.onpopstate = () => {
      let closeButton = document.getElementsByClassName('ril-close')[0];
      if (closeButton) {
        scrollbarValue = window.scrollY;
        closeButton.click();
      }
    }
  }

  const environmentAlts = [t("imgalts.env1"), t("imgalts.env2"), t("imgalts.env3"), t("imgalts.env4"), t("imgalts.env5")];
  const panoramaAlts = [t("imgalts.panap1"), t("imgalts.panap2"), t("imgalts.panap3"), t("imgalts.panap4"), t("imgalts.panap5")];
  const environmentImages = allData.images.edges.filter(({ node }) => node.childImageSharp.full.src.includes('env.jpg')).map(({ node }, index) => {
    if (node.childImageSharp) { node.childImageSharp.thumbAlt = environmentAlts[index]; }
    return node.childImageSharp
  });
  const panoramaImages = allData.images.edges.filter(({ node }) => node.childImageSharp.full.src.includes('pan.jpg')).map(({ node }, index) => {
    if (node.childImageSharp) { node.childImageSharp.thumbAlt = panoramaAlts[index]; }
    return node.childImageSharp
  });
  const mallowImages = allData.images.edges.filter(({ node }) => node.childImageSharp.full.src.includes('mallow.jpg')).map(({ node }) => node.childImageSharp);
  const blueImages = allData.images.edges.filter(({ node }) => node.childImageSharp.full.src.includes('blue.jpg')).map(({ node }) => node.childImageSharp);

  return (
    <div id="gallery" className={"text-center"}>
      <div style={{ maxWidth: 1080, margin: "auto" }}>
        <h1 style={{ paddingBottom: "0" }}>{t('gallery.gallery')}</h1>
        <h2>{t('gallery.environment')}</h2>
        <div className="gallery-container" onClick={(event) => { handleGalleryContainerClick(event) }}>
          <Gallery images={environmentImages} imgClass="gallery-img" colWidth={50} mdColWidth={33.3} />
        </div>

        <h2>{t('gallery.panorama')}</h2>
        <div className="gallery-container" onClick={(event) => { handleGalleryContainerClick(event) }}>
          <Gallery images={panoramaImages} imgClass="gallery-img" colWidth={50} mdColWidth={33.3} />
        </div>

        <h2>{t('gallery.mallow')}</h2>
        <div className="gallery-container" onClick={(event) => { handleGalleryContainerClick(event) }}>
          <Gallery images={mallowImages} imgClass="gallery-img" colWidth={50} mdColWidth={33.3} />
        </div>

        <h2>{t('gallery.blue')}</h2>
        <div className="gallery-container" onClick={(event) => { handleGalleryContainerClick(event) }}>
          <Gallery images={blueImages} imgClass="gallery-img" colWidth={50} mdColWidth={33.3} />
        </div>
      </div>
    </div>
  )
}

export default ApartmentGallery;