import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useTranslation } from "react-i18next"

const OverviewImg = () => {
  const { t } = useTranslation();
    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "house.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 6000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    return (
        <div className="container-fluid bg-1 text-center" style={{paddingRight:20, paddingLeft:20, paddingTop:20}}>
            <Img fluid={data.placeholderImage.childImageSharp.fluid} alt={t('imgalts.house')}/>
        </div>
    )
}

export default OverviewImg;